//

import {useRef} from "react";

// a custom hook for the selected cards
export default function useSelection(mutationCount) {
	const cards = useRef([])

	function setCards(newSelection) {
		cards.current = newSelection
	}

	function addCard(cardData) {

		// cardData object may really be identical here due to referencing!!
		if (cards.current.includes(cardData)) {
			// console.log('card is already selected')
			// toggle selection
			const tmpCards = cards.current.filter(tmpData => tmpData !== cardData)
			setCards(tmpCards)
		} else {
			// add to selection
			if (mutationCount <= cards.current.length) {
				// protection of overfilling - remove first item
				cards.current.splice(0,1)
			}
			cards.current.push(cardData)
		}
	}

	function reset() {
		setCards([])
	}

	function includes(cardData) {
		// cardData object may really be identical here due to referencing!!
		return (cards.current.includes(cardData))
	}

	function count() {
		return cards.current.length
	}

	return {cards: cards.current, addCard, reset, includes, count}
}
//


import {useEffect, useRef} from "react"
import {getCenterCoords} from "../helpers"
import classnames from "classnames";

export default function Placeholder({
    data
}) {
    const liveRef = useRef(null)
    const actualData = useRef(data)

    useEffect(() => {
        coordinate()
    })

    return (
        <div
            className={classnames('placeholder', {
                'd-none': !data.active
            })}
            ref={liveRef}
        >
            {/*<span className="placeholder-index">{actualData.current.index}</span>*/}
        </div>
    )

    function coordinate() {
        actualData.current.coords = getCenterCoords(liveRef.current)
    }
}
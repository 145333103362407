//


import {useRef} from "react";

export default function useActivePlaceholders(gaps) {

	const activePlhs = useRef([])

	function add(index){
		if (!activePlhs.current.includes(index)) {
			activePlhs.current.push(index)
		}
	}

	function remove(index) {
		if (activePlhs.current.includes(index)) {
			activePlhs.current.splice(
				activePlhs.current.indexOf(index),
				1
			)
		}
	}

	function getLargestIndex() {
		activePlhs.current.sort((a,b) => b - a)

		let index = -1
		do {
			index++
		} while (gaps.contains(activePlhs.current[index]) && (index < activePlhs.current.length))

		// Is the index valid? Then we found, what we wanted
		if (index < activePlhs.current.length) {
			// // is there a last gap just one index later?
			// if (gaps.isLastIndex(index + 1)) {
			// 	return
			// }
			return activePlhs.current[index]
		}
		else {
			return (-1)
		}
	}

	function reset() {
		activePlhs.current = []
	}

	function contains(index) {
		return (activePlhs.current.includes(index))
	}

	return {
		add,
		remove,
		getLargestIndex,
		reset,
		contains
	}

}
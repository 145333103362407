//


import {useRef} from "react";

export default function usePlaceholderGaps() {

	const gaps = useRef([])

	function add(plhIndex) {
		if (!gaps.current.includes(plhIndex)) {
			gaps.current.push(plhIndex)
		}
	}

	function remove(plhIndex) {
		if (gaps.current.includes(plhIndex)) {
			gaps.current.splice(
				gaps.current.indexOf(plhIndex),
				1
			)
		}
	}

	function reset() {
		gaps.current = []
	}

	function length() {
		return gaps.current.length
	}

	function closed() {
		return (gaps.current.length === 0)
	}

	function contains(plhIndex) {
		return (gaps.current.includes(plhIndex))
	}

	function getSmallestPlhGapIndex() {
		if (gaps.current.length > 0) {
			gaps.current.sort((a, b) => a - b)
			return (gaps.current[0])
		}
		else {
			return (-1)
		}
	}

	function getLargestPlhGapIndex() {
		if (gaps.current.length > 0) {
			gaps.current.sort((a, b) => a - b)
			return (gaps.current[gaps.current.length -1])
		}
		else {
			return (-1)
		}
	}

	function isLastIndex(index) {
		if (length() === 1) {
			return (getLargestPlhGapIndex() === index)
		}
		else {
			return false
		}
	}

	return {
		gaps: gaps.current,
		add,
		remove,
		reset,
		length,
		closed,
		contains,
		getSmallestPlhGapIndex,
		getLargestPlhGapIndex,
		isLastIndex
	}

}
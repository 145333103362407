//

import {FlowState} from "../FlowState";
import Card from './Card'
import Ceremony from './Ceremony'
import Scratchline from './Scratchline'
import {Pattern, Colors} from './Suit'
import Welcome from './Welcome'
import Placeholder from "./Placeholder";
import Stacks from "./Stacks";
import {View} from "../View";
import classnames from "classnames";
import {Anims} from "../Anims";
import GamePlayAudio from "./GamePlayAudio";


// This is more like the gs.view manager

export default function GameTable({gs}) {
    // consts & vars ------------------------------------------------------

    const layOver = (gs.flowState.current === FlowState.ANIM_BAD_MATCH)
    const stackScale = 0.7

    // MAIN ---------------------------------------------------------------
    // Effectiv a View manager
    // As this is a single page app the output components differ
    // according to the actual gs.view state. This is routed here:

    // conditional render -------------------------------------------------
    if (gs.view === View.WELCOME) {
        return <Welcome gs={gs} />
    }

    if (gs.view === View.SCRATCHLINE) {
        return <Scratchline gs={gs} />
    }

    if (gs.view === View.CEREMONY) {
        
        return (
            <Ceremony
                gs={gs}
            />
        )
    }

    if (gs.view === View.GAMEPLAY) {

        return (
            <div className="game-container">
                <GamePlayAudio gs={gs} />
                <div
                    className={classnames(
                        'overlay',
                        {'active':  layOver}
                    )}
                ></div>
                <Stacks
                    gs={gs}
                />
                {/* creating the patterns for the game cards */}
                <svg className="pattern-container" width="0" height="0">
                    {Colors.map((color) => (
                        <Pattern
                            key={color.slice(1, 7) + '-invers'}
                            color={color.slice(1, 7)}
                            invers="1"
                        />
                    ))}
                    {Colors.map((color) => (
                        <Pattern key={color.slice(1, 7)} color={color.slice(1, 7)}/>
                    ))}
                </svg>
                <div
                    role="none"
                    className="card-table"
                >
                    { gs.placeholdersData.current.map(data => {

                        data.active = gs.activePlaceHolders.contains(data.index)
                        data.gap = gs.placeholdersGaps.contains(data.index)

                        return (
                            <Placeholder
                                key={data.index}
                                data={data}
                            />
                        )
                    })}

                    { gs.tableCards.current.map((data, index) => {

                        return (
                            (gs.deckStackCoords.y !== 0)
                             && <Card
                                {...{
                                    gs,
                                    stackScale,
                                    index,
                                    data,
                                }}
                                placeholder={gs.placeholdersData.current[data.plhIndex]}
                                key={data.id}
                                selected={gs.selection.includes(data)}
                            />
                        )
                    })}
                    <div
                        className="exposition"
                    >
                        {
                            gs.exposedPlhs.current.map(data => {
                                data.active = true
                                return (
                                    <Placeholder
                                        key={data.index}
                                        data={data}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="card-table">
                <p>Still shuffling ...</p>
                <p>Game state: {gs.flowState.current}</p>
            </div>
        )
    }

}


// the main starting deck
import {useRef, useState} from "react";
import {Const} from "../Const";
import useRanking from "./useRanking";
import {FlowState} from "../FlowState";
import {View} from "../View";
import useTransitionManager from "./useTransitionManager";
import usePlaceholderGaps from "./usePlaceholderGaps";
import useActivePlaceholders from "./useActivePlaceHolders";
import useSelection from "./useSelection";
import useCardsToMove from "./useCardsToMove";
import Dim from "../Dim";
import GamePlayAudio from "./useAudio";

export default function useGameState({
	newFlowState,
	setNextFlowState,
	renderGame,
	persistRanking
}) {

	const audio = GamePlayAudio()
	const deck = useRef([])

// as badMatch reference
	const nullObj = useRef({})

// getting the constants into references; these default values will be
// to change in a user menu in later versions
// ----------------------

// Above the card deck and its properties
// active card dimensions:
	const dimensions = useRef(Const.dimensions)
// number of variations of each card dimension:
	const mutationCount = useRef(Const.mutationCount)
// min cards on table. Gets (re-)filled to this number of cards:
	const startCardCount = useRef(Const.startCardCount)
// min cards for the game. A deck will at least contain as many cards:
	const minGameCardCount = useRef(Const.minDeckCards)
// max cards on the table:
	const maxTableCards = useRef(Const.maxTableCards)

// General preparations
// The ranking. Gets loaded & persisted:
	const [ranking, setRanking, addRanking] = useRanking([])
// the game timers end value:
	const [gameTime, setGameTime] = useState(0)
	const expectedTime = useRef(60)

// The gameflow control.
// set this to true to trigger init - to reset game, remix the cards etc.
	const doInit = useRef(true)
// the current flowState:
	const flowState = useRef(FlowState.CREATED)
// the view to select in GameTable i.e. a viewmanager
	const [view, setView] = useState(View.WELCOME);
// controls the animation transitions:
	const TransitionManager = useTransitionManager(newFlowState);
// the flowState to change from children
	const nextFlowState = useRef(nullObj);
// the state to call after animation is finished
	const waitingFlowState = useRef(FlowState.NO_STATE)

// The stacks of the game:

// The players Prey stack html element reference:
	const discardStack = useRef(null)
// the rest cards html element reference:
	const deckStack = useRef(null)
// coords of deck and player stack:
	const deckStackCoords = useRef({x: 0, y: 0})
	const discardStackCoords = useRef({x: 0, y: 0})

// the placeholders:
// The card placeholders' data:
	const placeholdersData = useRef([])
// gaps in cards on the table and fitting methods:
	const placeholdersGaps = usePlaceholderGaps([])
// all indices of active placeholders and needed functionality:
	const activePlaceHolders = useActivePlaceholders(placeholdersGaps)
// Placeholders for badMatch or right cards animation.
	const exposedPlhs = useRef([])

// the cards:
	const tableCards = useRef([])
// The prey cards of the player.
	const playerCards = useRef([])
// Cards To Move.
	const cardsToMove = useCardsToMove()
// The selected cards to check and some methods.
	const selection = useSelection(Const.mutationCount)

// Other game representation objects.
// to set if there are no more cards allowed on the table:
	const tableIsFull = useRef(false)
// The false dimension of a match.
	const falseDimension = useRef(Dim.NONE.name)
// The badMatch mutations of the match
	const falseMutations = useRef([])


	return {
		audio,
		activePlaceHolders,
		cardsToMove,
		deck,
		deckStackCoords,
		deckStack,
		discardStackCoords,
		dimensions,
		discardStack,
		doInit: doInit.current,
		expectedTime: expectedTime.current,
		exposedPlhs,
		falseDimension,
		falseMutations,
		flowState,
		fullRender: renderGame,
		gameTime, setGameTime,
		mutationCount,
		minGameCardCount: minGameCardCount.current,
		maxTableCards: maxTableCards.current,
		newFlowState,
		nextFlowState,
		nullObj,
		persistRanking,
		playerCards,
		placeholdersData,
		placeholdersGaps,
		ranking, setRanking, addRanking,
		selection,
		setNextFlowState,
		startCardCount: startCardCount.current,
		stack: deck.current ? deck.current.length : 0,
		tableCards,
		tableIsFull,
		TransitionManager,
		view, setView,
		waitingFlowState,
	}

}



export default function GamePlayAudio({gs}) {
	const ojojooUrl = '../audio/ojojoo.mp3';
	const audio = new Audio(ojojooUrl);
	return (
		<span
			onLoad={() => {
				audio.play()
			}}
		>
		</span>
	)
}
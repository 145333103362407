//

// enums for the different "dimensions" of the cards
export default class Dim {
    static COLOR = new Dim('Farbe')
    // static BG_FILL = new Dim('Hintergrund')
    static BORDER = new Dim('Rand')
    static FORM = new Dim('Form')
    static NUMBER = new Dim('Anzahl')
    static FILL = new Dim('Fuellung')
    static NONE = new Dim('Keine')

    constructor(name) {
        this.name = name;
    }
}

import {useRef} from "react";


export default function GamePlayAudio() {
	const ojojoo = new Audio('../audio/ojojoo.mp3');
	const m_mm = new Audio('../audio/m-mm.mp3');

	function nono() {
		m_mm.play();
	}
	function yess() {
		ojojoo.play();
	}

	return {
		nono,
		yess
	}
}
//

import Dim from "./Dim";
import {Anims} from "./Anims";

export const Const = {
	dimensions: [
		Dim.FORM.name,
		Dim.NUMBER.name,
		Dim.COLOR.name,
		Dim.FILL.name,
	],
	passiveAnims: [
		Anims.GOOD_MATCH_EXPO,
		Anims.BAD_MATCH_EXPO,
	],
	neutralMutation: 4,
	startCardCount: 12,
	minDeckCards: 12,
	maxTableCards: 20,
	mutationCount: 3,
	cardAnimDuration: 300,			// ms
	watchmeAnimDuration: 700,		// ms
	expoScale: 1.3,
	stackScale: 1.0,
	delay: 40,
	skewBase: 0.2,
	onTableProps: {
		transitionTimingFunction: 'ease-in-out',
		transitionProperty: 'transform, opacity'
	},
	collectProps: {
		transitionTimingFunction: 'ease-in-out',
		transitionProperty: 'transform, opacity'
	},
	expositionProps: {
		transitionTimingFunction: 'ease-in-out',
		transitionProperty: 'transform, opacity'
	},
	stack: {
		deck: 'deckStack',
		table: 'tableStack',
		discard: 'discardStack'
	}
}
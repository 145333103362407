// Für alle nicht-Spiel-Screens

import GamePlayAudio from "./GamePlayAudio";

export default function Layout({ children }) {
  return (
    <div className="container" >
      <header>
        <h1>Match!</h1>
      </header>
      <main>{children}</main>
    </div>
  )
}

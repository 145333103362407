// Die "Startlinie", der letzte Klick vor dem Spielstart

import {FlowState} from "../FlowState";
import Layout from './Layout'
import {View} from "../View";

export default function Scratchline({gs}) {
  return (
    <Layout>
      <div>
        <h2>Scratchline</h2>
        <p>
          With the next click the game starts and the timer will be running.
        </p>
      </div>
      <div className="game-pad">
        <button
          type="button"
          onClick={() => {
              gs.newFlowState({
                  newState: FlowState.CALIBRATE,
              })
              gs.setView(View.GAMEPLAY)
          }}
        >
          Start Game
        </button>
      </div>
    </Layout>
  )
}

// Description: A StopWatch for the gameTime, based on my wrapper for react-timer-hook. The time reference is passed in. So it is possible to get the time at the end of the game outside the component.

import { useEffect } from 'react'
import useStopwatch from '../hooks/useStopwatch'

export default function StopWatch({ setGameTime }) {
  const { seconds, minutes, getTime } = useStopwatch({
    autoStart: true,
  })

  useEffect(() => {
    return () => {
      setGameTime(getTime())
    }
  })

  return (
    <div className="stop-watch" style={{ textAlign: 'center' }}>
      <div className="inner-stop-watch">
        <span className="digits-block minuts">{minutes}</span>
        <span className="colon">:</span>
        <span className="digits-block seconds">{seconds}</span>
      </div>
    </div>
  )
}

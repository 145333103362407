//


import classnames from "classnames";
import {useRef} from "react";
import WatchMe from "./WatchMe";

export default function Stack({
	gs,
	stackRef,
	classNames,
	label,
	count,
	watchmeCount = '-1'
}) {
	const styleRef = useRef({})
	const isEmpty = useRef(false)
	// const [render, setRender] = useState(0)
	let watchMe = false

	if (watchmeCount !== -1 && count === parseInt(watchmeCount) && !isEmpty.current) {
		isEmpty.current = true;
		watchMe = true
	}


	return (
		<WatchMe
			gs={gs}
			inProp={watchMe}
			elemRef={stackRef}
			styleRef={styleRef}
		>
			<div
				className={classnames(classNames)}
				ref={stackRef}
				style={styleRef.current}
			>
				<h3>{`${label}:`}</h3>
				<h2>{count}</h2>
				<h3>cards</h3>
			</div>
		</WatchMe>
	)


}
//


import {useRef} from "react";

export default function useTransitionManager() {

	const soloStarters = useRef([])	// collect starter callbacks & register solo transitions
													// not yet running
	const multiTransition = useRef(0)		// count RUNNING multi transitions
	const activeTsCounter = useRef(0);	// count RUNNING Transitions of both kind

	function registerTransition({
		solo = false,
		startCallback = null
	}) {

		if (solo) {
			// console.log('solo transition registered')
			// console.log('activeTsCounter.current: ' + activeTsCounter.current)

			soloStarters.current.push(startCallback)
			if (activeTsCounter.current === 0) {
				// console.log('directly starting solo transition')

				startNextSoloTransition()
			}
		}
		else {
			// console.log('multi transition starting')

			multiTransition.current = multiTransition.current + 1
			activeTsCounter.current = activeTsCounter.current + 1
		}
		return transitionFinished
	}

	function transitionFinished(solo = false) {
		// console.log('transition finished')

		activeTsCounter.current = activeTsCounter.current - 1

		// console.log('activeTsCounter.current: ' + activeTsCounter.current)

		if (solo) {
			// console.log('solo transition finished')
			// console.log('soloStarters.current.length')
			// console.log(soloStarters.current.length)

			if (soloStarters.current.length > 0) {
				startNextSoloTransition()
			}
			// else {
				// console.log('last solo transition finished')
			// }
		}
		else if (multiTransition.current > 0) {
			// console.log('multi transition finished')

			multiTransition.current = multiTransition.current -1
		}

		if (multiTransition.current === 0 && soloStarters.current.length > 0) {
			startNextSoloTransition()
		}
	}

	function startNextSoloTransition() {
		// console.log('starting (next) solo transition')
		const starter = soloStarters.current.splice(0, 1)[0]
		activeTsCounter.current = activeTsCounter.current + 1
		starter()
	}

	function everythingsFinished() {
		return (soloStarters.current.length === 0 && activeTsCounter.current === 0)
	}


	return {
		registerTransition, transitionFinished, everythingsFinished
	}
}
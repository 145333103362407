//

import {Anims} from "../Anims";
import useCardsCollection from "./useCardsCollection";

export default function useCardsToMove() {
	const cardsToMove = useCardsCollection()
	const cards = cardsToMove.cards

	function addCardData(cardData, anim) {
		cardData.anim = anim
		cards.current = [...cards.current, cardData]
	}

	function reset() {
		cards.current.forEach((card) => {
			card.anim = Anims.NONE
		})
		cards.current = []
	}

	function removeCard(cardData) {
		cards.current.forEach((card, index) => {
			if (cardData === card) {
				card.anim = Anims.NONE
				cards.current.splice(index, 1)
			}
		})
		return cards.current.length
	}



	return {
		cards: cards.current,
		addCardData,
		removeCard,
		reset,
		count: cardsToMove.count,
		indexOf: cardsToMove.indexOf,
		isLastCard: cardsToMove.isLastCard,
		isFirstCard: cardsToMove.isFirstCard
	}
}
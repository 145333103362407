// Die einzelne SpielKarte

import Suit from './Suit'
import {FlowState} from "../FlowState";
import {Transition} from 'react-transition-group'
import {useEffect, useRef, useState} from 'react'
import {V2Diff} from "../helpers"
import classNames from "classnames"
import {Const} from "../Const"
import {Anims} from "../Anims";


export default function Card({
	gs,
	data,
	selected,
	stackScale,
	placeholder,
}) {


	const [cardRender, setCardRender] = useState(0)
	const animPhase = useRef(-1)
	const [opacity, setOpacity] = useState(0)
	const buttonRef = useRef(null)
	const targetOffset = useRef({x:0, y:0})
	const startOffset = useRef({x:0, y:0})
	const stateStyles = useRef({
		exited: {
			opacity: 0,
		}
	})
	const xSkew = data.skew
	const ySkew = - data.skew
	const celebrate = useRef(false)
	const pity = useRef(false)


	// main ___________________________________________

	if (-1 === animPhase.current) {
		if ([
			Anims.ON_TABLE,
			Anims.RELOCATE,
			Anims.COLLECT,
			Anims.BAD_MATCH_EXPO,
			Anims.GOOD_MATCH_EXPO
		].includes(data.anim)) {

			// console.log('starting animation for card ' + data.id)
			// console.log('data.anim ' + data.anim)
			// console.log('data.actualStack ' + data.actualStack)

			animPhase.current = 0
		}
	}


	useEffect(() => {

		let scale = 1

		// origin as *some* fixed point on gametable, choosing first placeholder.
		const origin = gs.placeholdersData.current[0].coords

		switch(data.actualStack) {

			case Const.stack.table: {
				if ([Anims.ON_TABLE, Anims.RELOCATE].includes(data.anim)) {
					// console.log('animphase: ' + animPhase.current)

					if (animPhase.current === 0) {

						animPhase.current = 1
						data.inProp = false

						if (!data.relocate) {

							data.start = gs.deckStackCoords.current

							data.target = placeholder.coords
							scale = stackScale
						} else {
							data.relocate = false
							scale = 1
						}

						targetOffset.current = V2Diff(data.target, origin)
						startOffset.current = V2Diff(data.start, origin)
						setOpacity(1)
						gs.TransitionManager.registerTransition({})

						console.log('startOffset.current y: ' + startOffset.current.y)

						stateStyles.current = {
							entering: {
								transform: `matrix(1, ${-ySkew}, ${-xSkew}, 1, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								zIndex: 1200,
								...Const.expositionProps,
							},
							entered: {
								transform: `matrix(1, ${-ySkew}, ${-xSkew}, 1, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								zIndex: 0
							},
							exiting: {
								transform: `matrix(1, ${ySkew}, ${xSkew}, 1, ${startOffset.current.x}, ${startOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								...Const.expositionProps
							},
							exited: {
								transform: `matrix(${Const.stackScale}, ${ySkew}, ${xSkew}, ${Const.stackScale}, ${startOffset.current.x}, ${startOffset.current.y})`,
								zIndex: 1200,
							},
						}
						renderCard()
					}
					else if (animPhase.current === 1) {
						data.inProp = true
						animPhase.current = 2
						renderCard()
					}
				}

				else if (Anims.BAD_MATCH_EXPO === data.anim) {
					// xxxxxxxxxxxxxxx BAD MATCH YXXXXXXXXXXXXXXXXXXXX

					data.start = placeholder.coords
					data.badMatch = true

					targetOffset.current = V2Diff(data.target, origin)
					startOffset.current = V2Diff(data.start, origin)
					const zIndex = 1200

					if (animPhase.current === 0) {

						animPhase.current = 1
						data.inProp = false
						pity.current = true

						stateStyles.current = {
							entering: {
								transform: `matrix(${Const.expoScale}, ${ySkew}, ${xSkew}, ${Const.expoScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								...Const.onTableProps,
								zIndex,
							},
							entered: {
								transform: `matrix(${Const.expoScale}, ${ySkew}, ${xSkew}, ${Const.expoScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								...Const.onTableProps,
								zIndex,
							},
							exiting: {
								transform: `matrix(${scale}, ${ySkew}, ${xSkew}, ${scale}, ${startOffset.current.x}, ${startOffset.current.y})`,
								zIndex,
							},
							exited: {
								transform: `matrix(${scale}, ${ySkew}, ${xSkew}, ${scale}, ${startOffset.current.x}, ${startOffset.current.y})`,
								zIndex,
							},
						}
						renderCard()
					}
					else if (animPhase.current === 1) {
						// xxxxxxxxxxxxxxx BAD MATCH YXXXXXXXXXXXXXXXXXXXX

						data.inProp = true
						setTimeout(() => {
							animPhase.current = 2
							renderCard()
						}, 2 * Const.cardAnimDuration)
						renderCard()
					}
					else if (animPhase.current === 2) {
						// xxxxxxxxxxxxxxx BAD MATCH YXXXXXXXXXXXXXXXXXXXX

						animPhase.current = 3
						data.inProp = false

						stateStyles.current = {
							entering: {
								transform: `matrix(${scale}, ${ySkew}, ${xSkew}, ${scale}, ${startOffset.current.x}, ${startOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								...Const.onTableProps,
								zIndex,
							},
							entered: {
								transform: `matrix(${scale}, ${ySkew}, ${xSkew}, ${scale}, ${startOffset.current.x}, ${startOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								...Const.onTableProps,
								zIndex: 0,
							},
							exiting: {
								transform: `matrix(${Const.expoScale}, ${ySkew}, ${xSkew}, ${Const.expoScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								zIndex,
							},
							exited: {
								transform: `matrix(${Const.expoScale}, ${ySkew}, ${xSkew}, ${Const.expoScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								zIndex,
							},
						}

						renderCard()
					}
					else if (animPhase.current === 3) {
						// xxxxxxxxxxxxxxx BAD MATCH YXXXXXXXXXXXXXXXXXXXX

						data.inProp = true
						setTimeout(() => {
							animPhase.current = 4
							pity.current = false
							endBadMatchAnim()
						}, 2 * Const.cardAnimDuration )
					}
				}
				else if (Anims.GOOD_MATCH_EXPO === data.anim) {
					// xxxxxxxxxxxxxxx GOOD MATCH YXXXXXXXXXXXXXXXXXXXX

					data.start = placeholder.coords
					// data.goodMatch = true

					targetOffset.current = V2Diff(data.target, origin)
					startOffset.current = V2Diff(data.start, origin)
					const zIndex = 1200

					console.log('animphase: ' + animPhase.current)
					if (animPhase.current === 0) {
						// expo animation
						animPhase.current = 1
						data.inProp = false

						stateStyles.current = {
							entering: {
								transform: `matrix(${Const.expoScale}, ${ySkew}, ${xSkew}, ${Const.expoScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								zIndex,
								...Const.onTableProps,
							},
							entered: {
								transform: `matrix(${Const.expoScale}, ${ySkew}, ${xSkew}, ${Const.expoScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
								transitionDuration: `${Const.cardAnimDuration}ms`,
								...Const.onTableProps,
								zIndex,
							},
							exiting: {
								transform: `matrix(${scale}, ${ySkew}, ${xSkew}, ${scale}, ${startOffset.current.x}, ${startOffset.current.y})`,
								zIndex,
							},
							exited: {
								transform: `matrix(${scale}, ${ySkew}, ${xSkew}, ${scale}, ${startOffset.current.x}, ${startOffset.current.y})`,
								zIndex,
							},
						}
						renderCard()
					}
					else if (animPhase.current === 1) {
						// xxxxxxxxxxxxxxx GOOD MATCH YXXXXXXXXXXXXXXXXXXXX

						data.inProp = true
						setTimeout(() => {
							animPhase.current = 2
							renderCard()
						}, 2 * Const.cardAnimDuration)
						renderCard()
					}

					else if (animPhase.current === 2) {

						celebrate.current = true;
						data.inProp = true
						renderCard()
						setTimeout(() => {
							animPhase.current = 3
							endGoodMatchAnim()
						}, 2 * Const.cardAnimDuration )
					}
				}
				break
			}

			case Const.stack.discard: {
				// data.anim === Anims.COLLECT
				if (animPhase.current === 0) {
					animPhase.current = 1
					data.inProp = false
					const zIndex = 1200
					gs.TransitionManager.registerTransition({})

					targetOffset.current = V2Diff(data.target, origin)
					startOffset.current = V2Diff(data.start, origin)

					stateStyles.current = {
						entering: {
							opacity: 0,
							transform: `matrix(${stackScale}, ${ySkew}, ${xSkew}, ${stackScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
							zIndex,
							...Const.onTableProps,
							transitionDuration: `${Const.cardAnimDuration}ms`,
						},
						entered: {
							opacity: 0,
							transform: `matrix(${stackScale}, ${ySkew}, ${xSkew}, ${stackScale}, ${targetOffset.current.x}, ${targetOffset.current.y})`,
							zIndex : 0,
						},
						exiting: {
							zIndex,
						},
						exited: {
							opacity: 1,
							transform: `matrix(1, ${ySkew}, ${xSkew}, 1, ${startOffset.current.x}, ${startOffset.current.y})`,
							zIndex,
						},
					}
					renderCard()
				}
				else if (animPhase.current === 1) {
					data.inProp = true
					animPhase.current = 4
					renderCard()
				}
				break
			}

		}
	}, [animPhase.current])


	return (
		// simple animation of appearing card

		<Transition
			in={data.inProp}
			addEndListener={(node,done) => {
				node.addEventListener('transitionend', done, false)
			}}
			onEntered={cardTransitioned}
		>
			{ animState => {
				const style = {
					...stateStyles.current[animState],
					...getDelay(animState)
				}

				return(
					<button
						ref={buttonRef}
						className={classNames(
							"game-card",
							"trans-tl",
							animState,
							data.actualStack,
							{selected},
							{celebrate: celebrate.current},
							{pity: pity.current}
						)}
						style={style}
						type="button"
						onClick={(evt) => {
							evt.preventDefault()
							if (!Const.passiveAnims.includes(data.anim)) {
								gs.newFlowState({
									newState: FlowState.CARD_SELECTED,
									data
								})
								renderCard()
							}
						}}
						>
						<Suit {...{gs, data}} />
					</button>
				)
			}}
		</Transition>
	)

	function cardTransitioned() {

		// console.log('entering cardTransitioned')
		// console.log('animPhase.current: ' + animPhase.current)

		if (animPhase.current > 0) {
			animPhase.current = -1
			if (data.anim !== Anims.BAD_MATCH_EXPO
				&& data.anim !== Anims.GOOD_MATCH_EXPO) {
				gs.TransitionManager.transitionFinished()
				if (0 === gs.cardsToMove.removeCard(data)) {
					// due to inversion during animations
					gs.newFlowState({})
				}
			}
		}
	}

	function invertedAnim(actualState) {
		return ('entering' === actualState
			&& Const.stack.table === data.actualStack)
	}

	function getDelay(actualState) {
		// to differ normal from inverted animation and calc the delays
		if (['entering', 'exiting'].includes(actualState)) {
			const cardsToMoveIndex = gs.cardsToMove.indexOf(data)
			const delay = invertedAnim(actualState) ? (gs.cardsToMove.count() - cardsToMoveIndex - 1) * Const.delay : ((cardsToMoveIndex + 1 ) * Const.delay)
			return {
				transitionDelay: `${delay}ms`
			}
		}
		else {
			return {}
		}
	}


	function endBadMatchAnim() {
		animPhase.current = -1
		const waitingFor = gs.cardsToMove.removeCard(data)
		if (0 === waitingFor) {
			gs.TransitionManager.transitionFinished(true)
			gs.newFlowState({
				newState: FlowState.FINISH_BAD_MATCH,
				waitingState: FlowState.RUNNING
			})
		}
		data.badMatch = false
		renderCard()
	}
	function endGoodMatchAnim() {
		console.log('entering endGoodMatchAnim')
		animPhase.current = -1
		const waitingFor = gs.cardsToMove.removeCard(data)
		if (0 === waitingFor) {
			gs.TransitionManager.transitionFinished(true)
			gs.newFlowState({
				newState: FlowState.FINISH_GOOD_MATCH,
				waitingState: FlowState.COLLECT_CARDS
			})
		}
		data.start = data.target
		renderCard()
	}

	function renderCard() {
		setCardRender(prev => prev + 1)
	}
}

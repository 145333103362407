// custom hook for the ranking list

import {useState} from "react";

// localstorage is too late if the user closes the page upon ceremony
export default function useRanking(initialValue) {
	const [ranking, setRanking] = useState(initialValue)

	// collects ranking and saves score and name
	// keeping only the ten best
	function addRanking({playerName, prize, remains, time, score}) {
		const newRanking = [{playerName, time, remains, score, prize}, ...ranking]
			.sort((a, b) => b.score - a.score)
			.slice(0, 10)
		setRanking(newRanking)
	}

	return [ranking, setRanking, addRanking]
}
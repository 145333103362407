// Enums for the different states of the game
export const FlowState = {
// pre game flow states
	CREATED: 'CREATED',
	SCRATCHLINE: 'SCRATCHLINE',

// states for the game loop
// --------------------------

	// pure game flow
	CALIBRATE: 'CALIBRATE',
	RUNNING: 'RUNNING',
	CARD_SELECTED: 'CARD_SELECTED',
	CHECKING_MATCH: 'CHECKING_MATCH',
	COLLECT_CARDS: 'COLLECT_CARDS',
	REFILL: 'REFILL',
	ADD_CARD: 'ADD_CARD',
	FOUND_BAD_MATCH: 'FOUND_BAD_MATCH',
	FOUND_GOOD_MATCH: 'FOUND_GOOD_MATCH',

	// animation states - mostly blocking the game flow
	ANIM_REFILL: 'ANIM_REFILL',
	ANIM_CLOSE_GAP: 'ANIM_CLOSE_GAP',
	ANIM_COLLECT: 'ANIM_COLLECT',
	ANIM_ADD_CARD: 'ANIM_ADD_CARD',
	ANIM_LAST_CARD: 'ANIM_LAST_CARD',
	ANIM_WATCHME: 'ANIM_WATCHME',
	ANIM_BAD_MATCH: 'ANIM_BAD_MATCH',
	ANIM_GOOD_MATCH: 'ANIM_GOOD_MATCH',

	// finish complex animations
	FINISH_WATCHME: 'FINISH_WATCHME',
	FINISH_BAD_MATCH: 'FINISH_BAD_MATCH',
	FINISH_GOOD_MATCH: 'FINISH_GOOD_MATCH',

// states for game end
	FINISHED: 'FINISHED',
	RESTART: 'RESTART',

// states for debugging
	NO_STATE: 'NO_STATE',
}
// ein Wrapper für react-timer-hook mit der Methode getTime() und der
// zwei-Ziffern-Darstellung

import { useStopwatch } from 'react-timer-hook';

export default function StopWatch() {
  const { seconds, minutes, isRunning, start, pause, reset } = useStopwatch({
    autoStart: true,
  });

  function getTime() {
    return `${twoDigits(minutes)}:${twoDigits(seconds)}`;
  }

  function twoDigits(number) {
    return `0${number}`.slice(-2);
  }

  return {
    seconds: twoDigits(seconds),
    minutes: twoDigits(minutes),
    getTime,
    reset,
  };
}

//


import Stack from "./Stack";
import StopWatch from "./StopWatch";
import classNames from "classnames";
import {FlowState} from "../FlowState";
import {View} from "../View";

export default function Stacks({gs}) {
	// console.log('gs.tableIsFull: ', gs.tableIsFull)

	return(
		<div className="stacks">
			<Stack
				gs={gs}
				stackRef={gs.deckStack}
				classNames={('deck stack')}
				label={'main deck'}
				count={gs.deck.current.length}
				watchmeCount="0"
			/>
			<div className="gamepad">
				<StopWatch setGameTime={gs.setGameTime}/>
				{(!!gs.stack) && (
					<button
						type="button"
						className={classNames(
							"pad-button",
							{
								'inactive': gs.tableIsFull.current
							}
						)}
						onClick={() =>
							!gs.tableIsFull.current && gs.stack && gs.newFlowState({newState: FlowState.ADD_CARD})
						}
						onMouseDown={function(evt) {
							!gs.tableIsFull && btnDown(evt.target)
						}}
						onMouseUp={function(evt) {
							!gs.tableIsFull && btnBackUp(evt.target)
						}}
					>
						New Card
					</button>
				)}
				{(!gs.stack) && (
					<button
						type="button"
						className={classNames(
							"pad-button",
							{'disabled': gs.stack}
						)}
						onClick={() => {
							gs.setView(View.CEREMONY)
							!gs.stack && gs.newFlowState({newState: FlowState.FINISHED})
						}}
					>
						Finish
					</button>
				)}
			</div>
			<Stack
				gs={gs}
				stackRef={gs.discardStack}
				count={gs.playerCards.current.length}
				classNames={('score stack')}
				label={'your stack'}
			/>
		</div>
	)

	function btnDown(btn) {
		btn.classList.add('btn-down')
	}
	function btnBackUp(btn) {
		btn.classList.remove('btn-down')
	}
}
// Die Willkommens-Ansichts-Komponente

import Layout from './Layout'
import {View} from "../View";
import {FlowState} from "../FlowState";

export default function Welcome({gs}) {
  return (
    <Layout>
      <div className="welcome">
        <h2>Welcome</h2>
        <p>
          This is a game with seemingly simple rules, yet you may be
          experiencing some confusion. Enjoy!
        </p>
        {/* // TODO: Spielerläuterung */}
      </div>
      <div className="game-pad">
        <button
          onClick={() => {
              gs.newFlowState({newState: FlowState.SCRATCHLINE})
              gs.setView(View.SCRATCHLINE)
          }}
        >
          Let's Play
        </button>
      </div>
    </Layout>
  )
}

// Enums for the different animations of cards

export const Anims = {
	NONE: 'NONE',
	ON_TABLE: 'ON_TABLE',
	RELOCATE: 'RELOCATE',
	COLLECT: 'COLLECT',
	BAD_MATCH_EXPO: 'BAD_MATCH_EXPO',
	GOOD_MATCH_EXPO: 'GOOD_MATCH_EXPO',
	GOD_MATCH_EXPO: 'GOD_MATCH_EXPO',
}


//
import {useRef, useState} from 'react'
import {FlowState} from "../FlowState";
import Layout from './Layout'
import WatchMe from "./WatchMe";
import {View} from "../View";

// Die Siegerehrung
export default function Ceremony({
	gs
}) {
	// console.log('rendering ceremony')

	const prize = gs.playerCards.current.length
	const remains = gs.tableCards.current.length - prize
	const [playerName, setPlayerName] = useState('')
	// score calculation: credit for prize cards, penalty for time
	const seconds = parseInt(gs.gameTime.slice(0, 2)) * 60 + parseInt(gs.gameTime.slice(3, 5))
	const prizePoints = prize * 100
	const timePenalty = Math.round(Math.log(seconds) * 200)
	const score = prizePoints - timePenalty
	const nameInput = useRef()
	const missingName = useRef(false)
	const styleRef = useRef(null)
	const [render, setRender] = useState(0)
	const [visibilityClass, setVisibilityClass] = useState('');

	return (
		<Layout>
			<div className="ceremony-screen">
				<h2>Congrats!</h2>
				<dl className="results">
					<div>
						<dt>Cards</dt>
						<dd>{`${prize} Cards`}</dd>
					</div>
					<div>
						<dt>Prize</dt>
						<dd>{`= ${prizePoints} Points`}</dd>
					</div>
					<div>
						<dt>Remains</dt>
						<dd>{`${remains} Cards`}</dd>
					</div>
					<div>
						<dt>Time</dt>
						<dd>{`${seconds} s`}</dd>
					</div>
					<div>
						<dt>Time Penalty</dt>
						<dd>{`= ${timePenalty} Points`}</dd>
					</div>
					<div>
						<dt>Score</dt>
						<dd>{`${score} Points`}</dd>
					</div>
				</dl>
				<p>Enter your name and add your score to the ranking list.</p>

				<WatchMe
					gs={gs}
					inProp={missingName.current}
					elemRef={nameInput}
					styleRef={styleRef}
					onEnteredDo={() => {
						missingName.current = false
					}}
				>
					<input
						className={visibilityClass}
						ref={nameInput}
						style={styleRef.current}
						value={playerName}

						onChange={(evt) => {

							const newValue = evt.currentTarget.value
							setPlayerName(newValue)
							if ('' !== newValue) missingName.current = false
						}}
						onKeyUp={(evt) => {
							if (evt.key === 'Enter') {
								evt.currentTarget.blur()
								setVisibilityClass('d-none')
								gs.addRanking({playerName, prize, remains, time: gs.gameTime, score})
							}
						}}
					/>
				</WatchMe>

				<button
					className={visibilityClass}
					type="button"
					onClick={() => {
						if (playerName === '') {
							missingName.current = true
							renderCeremony()
						} else {
							setVisibilityClass('d-none')
							gs.persistRanking({playerName, prize, remains, time: gs.gameTime, score})
						}
					}}
				>
					Add Score to List
				</button>
				<h2>Ranking List</h2>
				<ul className="ranking-list">
					<li className="ranking-list-headers">
						<span className="position">#</span>
						<span className="score">Score</span>
						<span className="name">Name</span>
					</li>
					{gs.ranking.map((game, index) => (
						<li className="ranking-list-item" key={index}>
							<span className="position">{index + 1 + '.'}</span>
							<span className="score">{game.score}</span>
							<span className="name">{game.playerName}</span>
						</li>
					))}
				</ul>
			</div>
			<div className="game-pad">
				<button
					onClick={() => {
						gs.setView(View.SCRATCHLINE)
						gs.newFlowState({newState: FlowState.RESTART})
					}}
				>
					Restart
				</button>
			</div>
		</Layout>
	)

	function renderCeremony() {
		setRender(prev => prev + 1)
	}
}

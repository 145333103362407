// Die Umsetzung der Karten-Vorderseite. SVGs dynamisch aus den Attributen
// erzeugt, erweiterbar für andere Karten-Dimension

import Dim from '../Dim'
import {useRef} from "react";
import {isSet} from "util/support/types";

export const Colors = [
	'#da6d3e',
	'#337094',
	'#c8ba65',
	'#7cd65e',
	'#ecdad9'
]

export default function Suit({gs, data}) {
	const width = 90
	const height = 37
	const dashes = ['10,0', '1,10', '6,11', '23,16']
	const bw = 4 // stroke width (border width)
	const outerWidth = width + bw * 2
	const outerHeight = height + bw * 2

	let fill

	const dimData = useRef([])

	// console.log()
	// console.log('data: ' + JSON.stringify(data))

	// remove NOT-false dims from data
	gs.dimensions.current.forEach((dim) => {
		if (data.badMatch) {
			if (dim !== gs.falseDimension.current) {
				if (dim === Dim.NUMBER.name) {
					dimData.current[dim] = 0
				}
				else {
					dimData.current[dim] = 4
				}
			}
			return
		}
		dimData.current[dim] = data[dim]
	})



	const repeats = [...Array(dimData.current[Dim.NUMBER.name] + 1)]

	const color = Colors[dimData.current[Dim.COLOR.name]]

	const strokeColor = color

	const strokeDasharray = dimData.current[Dim.BORDER.name]
		? dashes[dimData.current[Dim.BORDER.name]]
		: dashes[0]

	// if (isSet().current[Dim.FILL.name]) {
		switch (dimData.current[Dim.FILL.name]) {
			case 0:
				fill = color
				break
			case 1:
				fill = 'none'
				break
			case 2:
				fill = `url(#pattern-hex-${color.slice(1, 7)})`
				break
			case 3:
				fill = `url(#pattern-hex-${color.slice(1, 7)}-invers)`
				break
			case 4:
				fill = 'none'
				break
		}
	// }
	// else {
	// 	fill = color
	// }

	switch (dimData.current[Dim.FORM.name]) {
		case 1: // rectangle
			return (
				<>
					{repeats.map((_, index) => (
						<svg
							className="symbol"
							key={index}
							width={outerWidth}
							height={outerHeight}
							viewBox={`0 0 ${outerWidth} ${outerHeight}`}
						>
							<rect
								x={bw}
								y={bw}
								// rx={2 * bw}
								// ry={2 * bw}
								width={width}
								height={height}
								fill={fill}
								stroke={strokeColor}
								strokeLinejoin="round"
								strokeLinecap="round"
								strokeWidth={bw}
								strokeDasharray={strokeDasharray}
							/>
						</svg>
					))}
				</>
			)
		case 2: // triangle
			return (
				<>
					{repeats.map((_, index) => (
						<svg
							className="symbol"
							key={index}
							width={outerWidth}
							height={outerHeight}
							viewBox={`0 0 ${outerWidth} ${outerHeight}`}
						>
							<polygon
								points={`${outerWidth / 2},${bw}  ${bw},${height + bw} ${
									width + bw
								},${height + bw}`}
								fill={fill}
								stroke={strokeColor}
								strokeLinejoin="round"
								strokeLinecap="round"
								strokeWidth={bw}
								strokeDasharray={strokeDasharray}
							/>
						</svg>
					))}
				</>
			)
		case 3: // diamond
			return (
				<>
					{repeats.map((_, index) => (
						<svg
							className="symbol"
							key={index}
							width={outerWidth}
							height={outerHeight}
							viewBox={`0 0 ${outerWidth} ${outerHeight}`}
						>
							<polygon
								points={`${outerWidth / 2},${bw} ${bw},${outerHeight / 2} ${
									outerWidth / 2
								},${height + bw} ${width + bw},${outerHeight / 2}`}
								fill={fill}
								stroke={strokeColor}
								strokeLinejoin="round"
								strokeLinecap="round"
								strokeWidth={bw}
								strokeDasharray={strokeDasharray}
							/>
						</svg>
					))}
				</>
			)
		case 0: // oval
		// default:
			return (
				<>
					{repeats.map((_, index) => (
						<svg
							className="symbol"
							key={index}
							width={outerWidth}
							height={outerHeight}
							viewBox={`0 0 ${outerWidth} ${outerHeight}`}
						>
							<ellipse
								cx={bw + width / 2}
								cy={bw + height / 2}
								rx={width / 2}
								ry={height / 2}
								fill={fill}
								stroke={strokeColor}
								strokeLinejoin="round"
								strokeLinecap="round"
								strokeWidth={bw}
								strokeDasharray={strokeDasharray}
							/>
						</svg>
					))}
				</>
			)
		case 5: // little rect
		default:
			return (
				<>
					{repeats.map((_, index) => (
						<svg
							className="symbol"
							key={index}
							width={outerWidth}
							height={outerHeight}
							viewBox={`0 0 ${outerWidth} ${outerHeight}`}
						>
							<ellipse
								cx={bw + width / 2}
								cy={bw + height / 2}
								rx={width / 3}
								ry={height / 2}
								fill={fill}
								stroke={strokeColor}
								strokeLinejoin="round"
								strokeLinecap="round"
								strokeWidth={bw}
								strokeDasharray={strokeDasharray}
							/>
						</svg>
					))}
				</>
			)
	}
}

export function Pattern({color, invers = false}) {
	//  Group the hexagon shapes
	//  Each path could have a class for more styling/animating options
	//  We're going to control the fill and stroke in the CSS for flexibility

	const stroke = '#' + color
	const fill = '#' + color
	const patternID = `pattern-hex-${color}` + (invers ? '-invers' : '')
	const strokeWidth = 30

	if (!invers) {
		const fill = 'none'
		return (
			<pattern
				id={patternID}
				x="0"
				y="0"
				width="11"
				height="18"
				patternUnits="userSpaceOnUse"
				viewBox="56 -254 112 190"
			>
				<g id="hexagon">
					<path
						fill={fill}
						stroke={stroke}
						strokeWidth={strokeWidth}
						d="M168-127.1c0.5,0,1,0.1,1.3,0.3l53.4,30.5c0.7,0.4,1.3,1.4,1.3,2.2v61c0,0.8-0.6,1.8-1.3,2.2L169.3-0.3 c-0.7,0.4-1.9,0.4-2.6,0l-53.4-30.5c-0.7-0.4-1.3-1.4-1.3-2.2v-61c0-0.8,0.6-1.8,1.3-2.2l53.4-30.5C167-127,167.5-127.1,168-127.1 L168-127.1z"
					></path>
					<path
						fill={fill}
						stroke={stroke}
						strokeWidth={strokeWidth}
						d="M112-222.5c0.5,0,1,0.1,1.3,0.3l53.4,30.5c0.7,0.4,1.3,1.4,1.3,2.2v61c0,0.8-0.6,1.8-1.3,2.2l-53.4,30.5 c-0.7,0.4-1.9,0.4-2.6,0l-53.4-30.5c-0.7-0.4-1.3-1.4-1.3-2.2v-61c0-0.8,0.6-1.8,1.3-2.2l53.4-30.5 C111-222.4,111.5-222.5,112-222.5L112-222.5z"
					></path>
					<path
						fill={fill}
						stroke={stroke}
						strokeWidth={strokeWidth}
						d="M168-317.8c0.5,0,1,0.1,1.3,0.3l53.4,30.5c0.7,0.4,1.3,1.4,1.3,2.2v61c0,0.8-0.6,1.8-1.3,2.2L169.3-191 c-0.7,0.4-1.9,0.4-2.6,0l-53.4-30.5c-0.7-0.4-1.3-1.4-1.3-2.2v-61c0-0.8,0.6-1.8,1.3-2.2l53.4-30.5 C167-317.7,167.5-317.8,168-317.8L168-317.8z"
					></path>
				</g>
			</pattern>
		)
	} else {
		const fill = '#' + color
		return (
			<pattern
				id={patternID}
				x="0"
				y="0"
				width="12"
				height="20.39"
				patternUnits="userSpaceOnUse"
			>
				<circle fill={fill} mask="url(#fade)" cx="5" cy="5" r="4.5"/>
				<circle fill={fill} mask="url(#fade)" cx="0" cy="15.39" r="4.5"/>
				<circle fill={fill} mask="url(#fade)" cx="12" cy="15.39" r="4.5"/>
			</pattern>
		)
	}
}

//

import {useRef} from "react";

export default function useCardsCollection() {
	const cardsToMove = useRef([])

	function addCardData(cardData) {
		cardsToMove.current = [...cardsToMove.current, cardData]
	}

	function reset() {
		cardsToMove.current = []
	}

	function removeCard(cardData) {
		cardsToMove.current.forEach((card, index) => {
			if (cardData === card) {
				cardsToMove.current.splice(index, 1)
			}
		})
		return cardsToMove.current.length
	}

	function count() {
		return cardsToMove.current.length
	}

	function indexOf(cardData) {
		return cardsToMove.current.indexOf(cardData)
	}

	function isLastCard(cardData) {
		return (cardData === cardsToMove.current[cardsToMove.current.length - 1])
	}

	function isFirstCard(cardData) {
		return (cardData === cardsToMove.current[0])
	}

	return {cards:cardsToMove, addCardData, removeCard, reset, count, indexOf, isLastCard, isFirstCard}
}
// Der Game-Manager. Sämtliche Spiel-Logik wird hier verarbeitet und an die Unterkomponenten übergeben.

import {useEffect, useRef, useState} from 'react'
import GameTable from './GameTable'
import {getCenterCoords, shuffle} from '../helpers.js'


import {FlowState} from "../FlowState"; // enum-like with all flow states
import {View} from "../View";
import PlaceholderData from "../PlaceHolderData"
import {Const} from "../Const"
import {Anims} from "../Anims"
import useGameState from "../hooks/useGameState";



export default function Game() {

    // Effectively a code flow state machine.

    const [render, setRender] = useState(0)
    const repeats = useRef(0)

    console.log('----------------- Game: next round ----------------------')

    const gs = useGameState({
        newFlowState,
        setNextFlowState,
        renderGame,
        persistRanking,
    })

    console.log('Game: gs.flowState.current', gs.flowState.current)

    checkNextFlowState()

    // For getting the center coords of both stacks.
    useEffect(() => {
        console.log('----------------- Game: useEffect ----------------------')

        if (null !== gs.deckStack.current
            && gs.deckStackCoords.current.y === 0) {
            calibrate()
            newFlowState({
                flowState: FlowState.CALIBRATE,
                waitingState: FlowState.ANIM_REFILL
            })
            renderGame()
        }
        switch (gs.flowState.current) {
            case FlowState.ANIM_GOOD_MATCH:
                gs.audio.yess()
                break
            case FlowState.ANIM_BAD_MATCH:
                gs.audio.nono()
                break
        }
    })


    // Fill gs.exposedPlhs according to gs.mutationCount.
    useEffect(() => {
        const newPlhs = []
        for (let p = 0; p < gs.mutationCount.current; p++) {
            newPlhs[p] = new PlaceholderData(p)
        }
        gs.exposedPlhs.current = newPlhs
    }, [gs.mutationCount.current])

    // render --------------------------------------------------

    return (
        <GameTable gs={gs} />
    )

    // functions -----------------------------------------------------
    // ---------------------------------------------------------------

    function setNextFlowState(flowStateObj) {

        // console.log('setNextFlowState')
        // console.log(flowStateObj)

        gs.nextFlowState.current = flowStateObj
    }
    function checkNextFlowState() {

        if (gs.nextFlowState.current !== gs.nullObj) {
            newFlowState(gs.nextFlowState.current)

            // console.log('checkNextFlowState: gs.nextFlowState.current', gs.nextFlowState.current)

            setNextFlowState(gs.nullObj)
        }
    }

    function setFlowState(newState) {
        gs.flowState.current = newState
        renderGame()
    }

    function newFlowState({
        newState = '',
        waitingState = '',
        data = null
    }) {
        // console.log('gs.flowState: ' + gs.flowState.current)
        // console.log('waitingState: ' + waitingState)
        // console.log('gs.waitingFlowState.current: ' + gs.waitingFlowState.current)
        // console.log('newState: ' + newState)

        let myNewState = newState
        // console.log('myNewState: ' + myNewState)

        // Stop anything else during WATCHME or MATCH_FALSE animations
        if (FlowState.ANIM_WATCHME === gs.flowState.current) {
            if (myNewState !== FlowState.FINISH_WATCHME) {
                // blocking anything else during Watchme animation
                return
            }
            else {
                setFlowState(waitingState)
                myNewState = waitingState
            }
        }
        else if (FlowState.ANIM_BAD_MATCH === gs.flowState.current) {

            if (myNewState !== FlowState.FINISH_BAD_MATCH) {
                // blocking anything else during ANIM_MATCH_FALSE animation
                return
            }
            else {
                gs.selection.reset()
                setFlowState(waitingState)
                gs.waitingFlowState.current = FlowState.NO_STATE
                myNewState = waitingState
            }
        }
        else if (FlowState.ANIM_GOOD_MATCH === gs.flowState.current) {

            if (myNewState !== FlowState.FINISH_GOOD_MATCH) {
                // blocking anything else during ANIM_MATCH_FALSE animation
                return
            }
            else {
                gs.selection.reset()
                setFlowState(waitingState)
                gs.waitingFlowState.current = FlowState.NO_STATE
                myNewState = waitingState
            }
        }
        else {
            // collect new states
            if (myNewState) {
                setFlowState(myNewState)
            }
            else if (gs.waitingFlowState.current !== FlowState.NO_STATE) {
                myNewState = gs.waitingFlowState.current
                gs.waitingFlowState.current = FlowState.NO_STATE
            }
            if (waitingState) {
                gs.waitingFlowState.current = waitingState
            }
        }

        const actualFlowState = myNewState ? myNewState
            : gs.flowState.current

        let nfs = {}
        switch (actualFlowState) {

            case FlowState.CREATED:
                // show welcome screen
                break;
            case FlowState.CALIBRATE:
                // first time evaluating the stack positions.
                console.log('CALIBRATE')
                nfs = {
                    newState: FlowState.CALIBRATE,
                    waitingState: FlowState.CALIBRATE
                }
                if (null !== gs.deckStack.current) {
                    if (gs.deckStackCoords.current.y !== 0) {
                        fillTable()
                        nfs = {
                            newState: FlowState.ANIM_REFILL,
                            waitingState: FlowState.RUNNING
                        }
                    }
                }
                setNextFlowState(nfs)
                break;
            case FlowState.ADD_CARD:
                // one card goes to the table
                cardOnTable(FlowState.RUNNING)
                break
            case FlowState.CARD_SELECTED:
                add2Selection(data)
                break
            case FlowState.COLLECT_CARDS:
                collectCards()
                break
            case FlowState.REFILL:
                refillTable()
                break
            case FlowState.SCRATCHLINE:
                gs.doInit && initGame()
                // nothing to do - just waiting for start click
                break
            case FlowState.NO_STATE:
                console.log('NO_STATE. nothing to do')
                break
            case FlowState.RESTART:
                gs.doInit = true
                newFlowState({newState: FlowState.SCRATCHLINE})
                break
            case FlowState.FOUND_BAD_MATCH:
                animBadMatchCards()
                // initiate animation
                break
            case FlowState.FOUND_GOOD_MATCH:
                animGoodMatchCards()
                // initiate animation
                break
            case FlowState.FINISHED:
                // going to ceremony
                break
            case FlowState.ANIM_COLLECT:
                // animate without waiting
                // TODO make a little bit more party
                break
            case FlowState.ANIM_REFILL:
                // animate without waiting
                break
            case FlowState.ANIM_ADD_CARD:
                // animate without waiting
                break
            case FlowState.ANIM_CLOSE_GAP:
                // animate without waiting
                break
            case FlowState.ANIM_LAST_CARD:
                // animate without waiting
                break
            case FlowState.ANIM_WATCHME:
                // WAITING for animation end
                break
            case FlowState.ANIM_BAD_MATCH:
                // WAITING for animation end
                if (gs.TransitionManager.everythingsFinished()) {
                    newFlowState({
                        newState: FlowState.FINISH_BAD_MATCH,
                        waitingState: FlowState.RUNNING
                    })
                }
                break
            case FlowState.ANIM_GOOD_MATCH:
                // WAITING for animation end
                if (gs.TransitionManager.everythingsFinished()) {
                    newFlowState({
                        newState: FlowState.FINISH_GOOD_MATCH,
                        waitingState: FlowState.RUNNING
                    })
                }
                break
            case FlowState.RUNNING:
                // default state, waiting for player input
                break

            default:
                throw('ERROR in Game.newFlowState - invalid flowState: ' + newState)
        }
    }

    function calibrate() {
        // refetch the coordinates of the stacks, therefore just to be called
        // from useEffect
        if (null === gs.deckStack.current) {
            throw new Error('calibrate: deckStack.current is null')
        }
        gs.deckStackCoords.current = getCenterCoords(gs.deckStack.current)
        gs.discardStackCoords.current = getCenterCoords(gs.discardStack.current)
    }

    function add2Selection(data) {
        // the card data gets selected
        gs.selection.addCard(data)
        if (gs.selection.count() === gs.mutationCount.current) {
            checkMatch([...gs.selection.cards, data])
        }
        else {
            newFlowState({newState: FlowState.RUNNING})
        }
        renderGame()
    }

    // Create all needed cards according to dimensions and mutationcount
    // in a recursive manner
    function createCards(
        newCards, // here will the cards be when finished
        dimIndex = 0,
        mutationCount,
        combination = [],
        startIndex = 0
    ) {

        if (dimIndex < gs.dimensions.current.length) {
            // diving deeper to build all variant parameters
            for (let i = 0; i < mutationCount; i++) {
                const nextCombination = [...combination, {dimIndex, mutation: i}]
                createCards(
                    newCards,
                    dimIndex + 1,
                    mutationCount,
                    nextCombination,
                    startIndex
                )
            }
        } else {

            const card = combination.reduce((cardObj, {dimIndex, mutation}) => {
                const prevId = cardObj.id ? cardObj.id : `${startIndex}`
                const cardIndex = prevId + mutation

                // generating random individual skew/rotation
                const skew = Math.round((2 * Const.skewBase * Math.random() - Const.skewBase) * 1000) * 0.001;

                return {
                    ...cardObj,
                    skew,
                    [gs.dimensions.current[dimIndex]]: mutation,
                    id: cardIndex,
                    plhIndex: -1,
                    actualStack: Const.stack.deck,
                    target: {x:0, y:0},
                    start: {x:0, y:0},
                    anim: Anims.NONE
                }
            }, {})
            newCards.push(card)
        }
    }

    function getTableCard(cardID) {

        const result = gs.tableCards.current.filter((card) => (card.id === cardID))
        if (result.length) {
            return (result[0])
        }
        else {
            return null
        }
    }

    // Combines creating cards and filling the table
    function buildStack() {
        // console.log('entering buildStack')

        // reset, if not first time call
        gs.deck.current = []
        setTableCards(() => [])
        gs.playerCards.current = []
        let startIndex = 0

        // eslint-disable-next-line prefer-const
        let newCards = [] // will hold the cards to create
        while (
            newCards.length < gs.minGameCardCount
            // || newCards.length < startCardCount
            ) {
            // If less than startCardCount cards, add another set to the stack.
            // Possible when low numbers on dimension and mutation count.
            createCards(
                newCards,
                0,
                gs.mutationCount.current,
                [],
                startIndex
            )
            ++startIndex
        }

        // shuffle, fill gs.deck and fill table
        newCards = shuffle(newCards)
        newCards = shuffle(newCards)
        newCards = shuffle(newCards) // ;)

        gs.deck.current = [...newCards]
    }

    function fillTable() {
        // console.log('entering fillTable')

        // fill table with cards from deck
        for (let i = 0; i < gs.startCardCount; i++) {
            cardOnTable()
        }
    }

    function cardOnTable(flowStateAfter = null) {
    // console.log('entering card on table')

        // Put a card from gs.deck to table
        const movingCard = gs.deck.current.pop()

        gs.placeholdersData.current.every((plhData) => {
            // use the first empty placeholder:

            // find first gap or inactive placeholder
            if (
                gs.placeholdersGaps.contains(plhData.index)
                || !gs.activePlaceHolders.contains(plhData.index
            )) {

                plhData.cardID = movingCard.id
                gs.activePlaceHolders.add(plhData.index)
                gs.placeholdersGaps.remove(plhData.index)

                movingCard.plhIndex = plhData.index
                movingCard.actualStack = Const.stack.table
                movingCard.inProp = false
                gs.cardsToMove.addCardData(movingCard, Anims.ON_TABLE)

                return false  // end the every-loop
            } else {
                return true  // proceed the every-loop
            }

        })
        setTableCards((currentCards) => [...currentCards, movingCard])
        if (tableCardCount() === gs.maxTableCards) {
            gs.tableIsFull.current = true
            renderGame()
        }
        if (null !== flowStateAfter) {
            newFlowState({newState: flowStateAfter})
        }
    }

    function setTableCards(fct) {
        gs.tableCards.current = fct(gs.tableCards.current)
    }

    function tableCardCount() {
        return (gs.tableCards.current.length - gs.playerCards.current.length)
    }

    function checkMatch(cards) {

        // Are these cards a match?
        let isMatch = true
        for (let d = 0; d < gs.dimensions.current.length; ++d) {
            // A set is the easy technique to distinguish the found dimensions
            const foundDims = new Set()
            for (let m = 0; m < gs.mutationCount.current; ++m) {
                foundDims.add(cards[m][gs.dimensions.current[d]])
                // count mutations for analysis in badMatch case
                gs.falseMutations.current[m] = gs.falseMutations.current[m] ?
                    gs.falseMutations.current[m] + 1 : 1
            }
            // All dimensions the same or all different? Therefore, 1 or gs.mutationCount
            isMatch =
                isMatch && // has to be for ALL dimensions
                (foundDims.size === 1 || foundDims.size === gs.mutationCount.current)
            if (!isMatch) {
                gs.falseDimension.current = gs.dimensions.current[d]
                break
            }
        }
        if (isMatch) {
            newFlowState({
                newState: FlowState.FOUND_GOOD_MATCH
            })
        }
        else {
            newFlowState({
                newState: FlowState.FOUND_BAD_MATCH
            })
        }
        return isMatch
    }


    function collectCards() {

        gs.selection.cards.forEach(card => {
            // shorthand for placeholder

            const plh = gs.placeholdersData.current[card.plhIndex]

            // move cards to players stack
            // 1) register actualStack and card for animation
            card.actualStack = Const.stack.discard
            gs.cardsToMove.addCardData(card, Anims.COLLECT)
            // 2) register new animation path for card
            // card.start.x = plh.coords.x
            // card.start.y = plh.coords.y
            card.target.x = gs.discardStackCoords.current.x
            card.target.y = gs.discardStackCoords.current.y
            card.inProp = false
            gs.playerCards.current.push(card.id)

            // resetting
            // opening the gap
            gs.placeholdersGaps.add(card.plhIndex)
            plh.gap = true
            // delete references between plh to card
            plh.cardID = ''
            card.plhIndex = -1


        })

        if (tableCardCount() < gs.maxTableCards) {
            gs.tableIsFull.current = false
        }

        newFlowState({
            newState: FlowState.ANIM_COLLECT,
            waitingState: FlowState.REFILL
        })
        gs.selection.reset()
    }

    function prepareCardForExposition(cardData, index, animation) {
        const targetPlh = gs.exposedPlhs.current[index]
        // Register new animation path for cardData
        cardData.target.x = targetPlh.coords.x
        cardData.target.y = targetPlh.coords.y
        cardData.inProp = false
        gs.cardsToMove.addCardData(cardData, animation)
    }

    function animBadMatchCards() {


        gs.TransitionManager.registerTransition({
            solo: true,
            startCallback: startBadMatchAnim
        })

        renderGame()
    }

    function animGoodMatchCards() {

        gs.TransitionManager.registerTransition({
            solo: true,
            startCallback: startGoodMatchAnim
        })
        renderGame()
    }

    function startBadMatchAnim() {

        newFlowState({
            newState: FlowState.ANIM_BAD_MATCH,
            waitingState: FlowState.RUNNING
        })

        gs.selection.cards.forEach((cardData, index) => {
            prepareCardForExposition(cardData, index, Anims.BAD_MATCH_EXPO)
        })
    }

    function startGoodMatchAnim() {

        newFlowState({
            newState: FlowState.ANIM_GOOD_MATCH,
            waitingState: FlowState.RUNNING
        })

        gs.selection.cards.forEach((cardData, index) => {
            prepareCardForExposition(cardData, index, Anims.GOOD_MATCH_EXPO)
        })

    }

    // update and persist ranking
    function persistRanking({playerName, time, remains, score, prize}) {

        // ranking is not updated yet, so add the new score here:
        gs.addRanking({playerName, time, remains, score, prize})

        // persist the ranking changes:
        localStorage.setItem(
            'ranking',
            JSON.stringify([{playerName, score}, ...gs.ranking])
        )
    }

    function buildPlaceholders() {
        // base state for placeholders
        // init everything placeholder infos
        const tmpPHData = []
        gs.activePlaceHolders.reset()
        gs.placeholdersGaps.reset()

        // create placeholders for the main table cards
        for (let index = 0; index < gs.startCardCount; ++index) {
            tmpPHData.push(new PlaceholderData(index))
            // these placeholders are active and have a gap i.e. should get filled
            gs.placeholdersGaps.add(index)
            gs.activePlaceHolders.add(index)
        }
        // create placeholders for up to max table cards - deactivated
        for (let index = gs.startCardCount; index < gs.maxTableCards; ++index) {
            tmpPHData.push(new PlaceholderData(index))
            // these placeholders are not active and no gap
        }
        gs.placeholdersData.current = tmpPHData
    }

    function initGame() {
        // to not repeat this process:
        gs.doInit = false

        // Load the ranking if given.
        const savedRanking = JSON.parse(localStorage.getItem('ranking'))
        if (savedRanking) {
            gs.setRanking(JSON.parse(localStorage.getItem('ranking')))
        }

        // persist gametable structure i.e. placeholders
        buildPlaceholders()
        // cards on table ...
        buildStack()
        //calculate expected time
        const allCardsCount = gs.deck.current.length + gs.tableCards.current.length

        gs.expectedTime = (gs.dimensions.current.length * allCardsCount * gs.mutationCount.current * 0.5)

    }

    function refillTable() {

        // alert('next refill')

        if (!gs.placeholdersGaps.closed() && gs.deck.current.length) {
            const activePlhIndex = gs.activePlaceHolders.getLargestIndex()
            const largestGapIndex = gs.placeholdersGaps.getLargestPlhGapIndex()

            if (
                largestGapIndex >= gs.startCardCount
                && largestGapIndex > activePlhIndex
            ) {
                // just remove empty gaps at end of table cards
                removeGap(largestGapIndex)
                newFlowState({newState: FlowState.REFILL})
            }
            else if (activePlhIndex >= gs.startCardCount) {
                // console.log('refillTable: close gap and come back')
                closeGap(activePlhIndex)
                newFlowState({
                    newState: FlowState.ANIM_REFILL,
                    waitingState: FlowState.REFILL
                })
            }
            else {
                // console.log('refillTable: need new card?')
                cardOnTable(FlowState.REFILL)
            }
        }
        else if (gs.deck.current.length === 0) {
            if (gs.tableCards.current.length === gs.playerCards.current.length) {
                // console.log('all cards on players stack -
                gs.setView(View.CEREMONY)
                newFlowState({newState: FlowState.FINISHED})
            }
            else {
                newFlowState({newState: FlowState.RUNNING})
                // console.log('no cards to refill -> return to RUNNING')
            }
        }
        else {
            // console.log('No placeholders to close: return to RUNNING')
            newFlowState({newState: FlowState.RUNNING})
        }
        renderGame()

    }

    function closeGap(activePlhIndex) {
        // activePlhIndex marks the last !! card in the > minTableCardsCount region

        // closing a gap in placeholders with the card at placeholder
        // nr. activePlhIndex
        const plhGapIndex = gs.placeholdersGaps.getSmallestPlhGapIndex()
        const gapPlh = gs.placeholdersData.current[plhGapIndex]
        if (
            plhGapIndex > activePlhIndex
        ) {

            // just remove the gap
            gs.placeholdersGaps.remove(plhGapIndex)
            gapPlh.gap = false
            gapPlh.cardID = -1
            if (plhGapIndex >= gs.startCardCount) {

                gapPlh.active = false
                gs.activePlaceHolders.remove(plhGapIndex)
            }
        }
        else {
            // move card from end of table to the gap
            const activePlh = gs.placeholdersData.current[activePlhIndex]
            const card = getTableCard(activePlh.cardID)

            // adapting card props
            card.plhIndex = plhGapIndex
            card.start.x = activePlh.coords.x
            card.start.y = activePlh.coords.y
            card.target.x = gapPlh.coords.x
            card.target.y = gapPlh.coords.y
            card.relocate = true
            card.inProp = false

            // collecting card for animation
            gs.cardsToMove.addCardData(card, Anims.RELOCATE)

            // adapting gap placeholder props and deregister
            gapPlh.cardID = card.id
            gapPlh.gap = false
            gs.placeholdersGaps.remove(plhGapIndex)
            if (plhGapIndex >= gs.startCardCount) {
                gapPlh.active = false
            }

            // adapting active placeholder and deregister
            // since this is the last card, no need to declare a gap

            // do this after animation ??
            if (activePlhIndex >= gs.startCardCount) {
                activePlh.active = false
            }
            gs.activePlaceHolders.remove(activePlhIndex)
        }

    }

    function removeGap(plhIndex) {
        if (gs.placeholdersGaps.contains(plhIndex)) {
            const plh = gs.placeholdersData.current[plhIndex]
            plh.gap = false
            plh.active = false
            gs.placeholdersGaps.remove(plhIndex)
            gs.activePlaceHolders.remove(plhIndex)
        }
    }

    function renderGame() {
        setRender(prev => prev + 1)
    }
}



//


import {useEffect, useRef} from "react";
import {Const} from "../Const";
import {Transition} from "react-transition-group";
import {RGBtoHex} from "../helpers";
import classnames from "classnames";
import {FlowState as gameState} from "../FlowState";

export default function WatchMe({
	gs,
	inProp,
	children,
	styleRef,
	elemRef,
	onEnteredDo = null
}) {

	const running = useRef(false)
	const maxAnimations = useRef(3)
	const animationCount = useRef(0)
	const lastAnimation = useRef(0)
	const repeatAnimation = useRef(false)
	const blinkState = useRef(false)
	const singlePassDuration = Math.round(Const.watchmeAnimDuration  / (maxAnimations.current))
	const inDuration = Math.round(0.1 * singlePassDuration)
	const outDuration = Math.round(0.9 * singlePassDuration)
	const bgColor = useRef('#dd00ff')

	const stateStyles = useRef({
		entering: {
			transform: `scale(1.05, 1.05)`,
			transitionDuration: `${inDuration}ms`,
			backgroundColor: '#ffffff'
		},
		entered: {
			transform: `scale(1.05, 1.05)`,
			transitionDuration: `${inDuration}ms`,
			backgroundColor: '#ffffff'
		},
		exiting: {
			transform: `scale(1)`,
			transitionDuration: `${outDuration}ms`,
			backgroundColor: bgColor.current
		},
		exited: {
			transform: `scale(1)`,
			transitionDuration: `${outDuration}ms`,
			// backgroundColor: bgColor.current
		},
	})

	useEffect(() => {
		// Getting and saving the elements background-color
		if (elemRef) {
			bgColor.current = RGBtoHex(window.getComputedStyle(elemRef.current).getPropertyValue('background-color'))
			stateStyles.current.exiting.backgroundColor = bgColor.current
			stateStyles.current.exited.backgroundColor = bgColor.current
		}
	}, [elemRef])


	if (inProp && animationCount.current === 0) {
		// console.log('animation start')

		gs.TransitionManager.registerTransition({
			solo: true,
			startCallback: startBlinking
		})
	}
	else if(
		animationCount.current > 0
		&& lastAnimation.current < animationCount.current
	) {
		// console.log('animation in progress')

		lastAnimation.current = animationCount.current
		blinkState.current = !blinkState.current // on - off - on - off ...
		repeatAnimation.current = true
	}

	if (repeatAnimation.current) {
		// console.log(' ----------------- Watchme circle ----------------')
		// console.log('blinkState.current: ' + blinkState.current)
		// console.log('animationCount.current: ' + animationCount.current)
	}

	return(
		<Transition
			in={blinkState.current}
			timeout={singlePassDuration}
			onEntered={() => {
				transitionRepeat()
			}}
			onExited={() => {
				// console.log('watchme exiting')
				transitionRepeat()
			}}
		>
			{ animState => {
				styleRef.current = {
					...stateStyles.current[animState]
				}
				return (
					<>
						<div
							className={classnames(
								"watchme-overlay",
								{"active": running.current}
							)}
						></div>
						{children}
					</>
				)
			}}

		</Transition>
	)

	function startBlinking() {
		gs.setNextFlowState({newState: gameState.ANIM_WATCHME})
		onEnteredDo && onEnteredDo()
		running.current = true
		blinkState.current = true
		repeatAnimation.current = true
	}

	function stopBlinking() {
		running.current = false
		// reset everything for next full animation
		repeatAnimation.current = false
		animationCount.current = 0
		blinkState.current = false
		lastAnimation.current = -1
		gs.TransitionManager.transitionFinished(true)
		gs.newFlowState({
			newState: gameState.FINISH_WATCHME,
			waitingState: gameState.RUNNING
		})
	}

	function transitionRepeat() {
		if (animationCount.current === (maxAnimations.current * 2) - 1) {
			// console.log('finish transition cycle')

			stopBlinking()
		}
		else {
			// console.log('next transition cycle')
			animationCount.current = animationCount.current + 1
			gs.fullRender()
		}
	}
}

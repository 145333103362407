//

export function shuffle(sorted) {
  const shuffled = [...sorted];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}


export function getCenterCoords(elem) {

  // console.log('elem')
  // console.log(elem)

  if (window) {
    const {top, left, width, height} = elem.getBoundingClientRect()
    const yOffset = window.scrollY
    return {
      x: left + (width * 0.5),
      y: yOffset + top + (height * 0.5)
    }
  }
  else {
    throw('getCenterCoords used outside browser scope. Cant proceed.')
  }
}

export function V2Diff(v2a, v2b) {
  return {x:(v2a.x - v2b.x), y:(v2a.y - v2b.y)}
}


export function RGBtoHex(rgbString) {

  // expected input format example: rgb(174, 171, 160)
    const innerString = rgbString.substring(4, rgbString.length -1)
    const rgbArr = innerString.split(', ')

  // convert to number and then to hex string
    let r = parseInt(rgbArr[0]).toString(16)
    let g = parseInt(rgbArr[1]).toString(16)
    let b = parseInt(rgbArr[2]).toString(16)

  // adding 0 to front if needed
    if (r.length === 1)
      r = "0" + r;
    if (g.length === 1)
      g = "0" + g;
    if (b.length === 1)
      b = "0" + b;

    return `#${r}${g}${b}`
}